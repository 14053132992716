import Link from "next/link";
import Image from "next/image";

const HomeProjectList = props => {
  let projects = props.data.map((item, index) => {
    return (
      <Link key={index} href={props.path + item.slug}>
        <div className="flex hover:shadow-lg p-6 mb-6 md:px-6 md:pt-6 md:pb-4 border border-indigo-100 group cursor-pointer">
          <div className="flex flex-col md:flex-row">
            <div className="hidden md:block md:mr-6 md:w-96">
              <Image
                alt={item.title}
                src={
                  item.feature_image
                    ? item.feature_image
                    : "/images/rectangle3.jpg"
                }
                width="370"
                height="232"
                layout="fixed"
                objectFit="cover"
                quality={100}
              />
            </div>
            <div className="block md:hidden w-full">
              <Image
                alt={item.title}
                src={
                  item.feature_image
                    ? item.feature_image
                    : "/images/rectangle3.jpg"
                }
                width="370"
                height="232"
                layout="responsive"
                objectFit="cover"
                quality={100}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex-1">
                <div className="text-black font-headline text-2xl font-semibold mt-4 md:mt-0 mb-1">
                  {item.title}
                </div>
                <div className="font-body text-base font-medium mb-6">
                  {item.meta_title}
                </div>
                <div className="font-body text-base text-gray-600 font-normal h-24 overflow-hidden group-hover:h-24 group-hover:overflow-hidden group-hover:text-ellipsis group-hover:mb-2">
                  {item.custom_excerpt}
                </div>
              </div>

              <div className="flex-auto"></div>
              <div className="md:hidden flex flex-initial justify-end md:border-t border-gray-200 items-end group-hover:pt-4 pt-6">
                <Link href={props.path + item.slug}>
                  <button className="text-right font-body text-sm font-extrabold text-blue-600 md:py-6 outline outline-0 focus:outline-0">
                    {props.title}
                  </button>
                </Link>
              </div>
              <div className="hidden md:flex opacity-0 group-hover:opacity-100 flex-initial justify-end md:border-t border-gray-200 items-end group group-hover:pt-4 pt-6">
                <Link href={props.path + item.slug}>
                  <button className="text-right font-body text-sm font-extrabold text-blue-600 outline outline-0 focus:outline-0">
                    {props.title}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  });
  return <div className="">{projects}</div>;
};

export default HomeProjectList;
