const HomeService = props => {
    let homeItems = props.service.map((item, index) => {
        return (
            <div
                key={index}
                className="flex flex-col items-center md:items-start pb-0 md:pb-1 hover:pb-0 border-b-2 border-gray-300 md:hover:border-b-4 md:hover:border-black"
            >
                <div className="">
                    <p className="text-center md:text-left mb-2 font-extrabold font-headline text-8xl md:text-8xl sm:w-4/5 block">
                        {item.title}
                    </p>
                    <p className="text-center md:text-left font-headline block text-3xl md:text-3xl font-normal">
                        {item.custom_excerpt}
                    </p>
                </div>
                <div className="my-6 md:mt-12 md:mb-6">
                    <div
                        className="text-center md:text-left font-headline block text-2xl md:text-2xl font-normal text-gray-600">
                        {item.meta_title}
                    </div>
                    <div
                        className="text-center md:text-left font-headline block text-2xl md:text-2xl font-normal text-gray-600">
                        {item.meta_description}
                    </div>
                </div>
            </div>
        );
    });
    return <>{homeItems}</>;
};

export default HomeService;
