import Image from "next/image";

const Award = () => {
  return (
    <>
      <div className="px-5 mid-home-column">
        <h1 className="text-3xl font-extrabold text-center font-body">
          Recognitions
        </h1>
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div className=" basis-1/2 flex flex-row-reverse lg:flex-row gap-6 sm:gap-12 items-center">
            <div className="basis-36">
              <Image
                alt="ICT Award"
                src="/images/home/ict_award.png"
                width="96"
                height="250"
                layout="intrinsic"
                quality={100}
              />
            </div>
            <div className="flex flex-col items-start justify-start gap-3 sm:gap-6">
              <Image
                alt="Basis ICT Award"
                src="/images/home/basis.jpg"
                width="190"
                height="50"
                quality={100}
              />
              <div className="sm:text-2xl font-thin">
                WINNER OF THE{" "}
                <p className=" font-extrabold text-xl sm:text-3xl">
                  BASIS ICT AWARD 2020
                </p>
              </div>
              <div>
                <p className="font-thin">
                  <span className="font-bold">Category</span> INDUSTRIAL (HC-I),
                  Supply chain Logistics
                </p>
                <p className="font-thin mt-2">
                  <span className=" font-bold">Product</span> ODMS (Off Dock
                  Management System)
                </p>
              </div>
            </div>
          </div>
          <div className="lg:basis-1/2 flex  gap-12 items-center">
            <div className="shrink-0 sm:shrink lg:w-full">
              <img
                src="/images/home/brac-daily-star-award.png"
                alt="BRAC Daily Star Award"
                className="w-28 sm:w-auto"
              />
            </div>
            <div className="flex flex-col gap-3 sm:gap-6">
              <img
                src="/images/home/brac-daily-star-logo.svg"
                className="w-72"
                alt="BRAC Daily Star Logo"
              />
              <div className="sm:text-2xl font-thin">
                WINNER OF THE{" "}
                <p className="font-extrabold text-xl sm:text-3xl">
                  BRAC Bank & The Daily Star 8th ICT AWARD
                </p>
              </div>
              <div>
                <p className="font-thin">
                  <span className="font-bold">Category</span> ICT Solution
                  Provider of the Year 2022 (International Market Focus)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" mid-home-column">
        <div className="items-center mt-20 sm:mt-28 mb-7 sm:mb-14">
          <h1 className="text-3xl font-extrabold text-center font-body">
            Memberships
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 content-center mt-9 sm:mt-16 gap-4">
            <div className="sm:mb-0">
              <div className="text-center">
                <Image
                  className="text-right w-52 h-14"
                  alt="Basis logo"
                  src="/images/home/basis.jpg"
                  width="201"
                  height="52"
                  layout="intrinsic"
                  quality={100}
                />
              </div>
              <div className="items-center my-4">
                <h2 className="mb-2 text-xl font-normal text-center font-body">
                  Bangladesh Association of Software and Information Services
                </h2>
                <h2 className="text-xl font-semibold text-center text-gray-500 font-body">
                  Since 2008
                </h2>
              </div>
            </div>
            <div>
              <div className="text-center">
                <Image
                  className="text-right w-52 h-14"
                  alt="certificate"
                  src="/images/home/cci.svg"
                  width="201"
                  height="52"
                  layout="intrinsic"
                  quality={100}
                />
              </div>
              <div className="items-center my-4">
                <h2 className="mb-2 text-xl font-normal text-center font-body">
                  Bangladesh-Malaysia Chamber of Commerce and Industry
                </h2>
                <h2 className="text-xl font-semibold text-center text-gray-500 font-body">
                  Since 2018
                </h2>
              </div>
            </div>
            <div>
              <div className="text-center flex justify-center">
                <Image
                  className="text-right w-52 h-14"
                  alt="DCCI logo"
                  src="/images/home/dcci-logo.png"
                  width="340"
                  height="52"
                  layout="intrinsic"
                  quality={100}
                />
              </div>
              <div className="flex flex-col items-center my-6">
                <h2 className="mb-2 text-xl font-normal text-center font-body max-w-[300px]">
                  Dhaka Chamber of Commerce and Industry
                </h2>
                <h2 className="text-xl font-semibold text-center text-gray-500 font-body">
                  Since 2018
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Award;
