import Image from "next/image";
import dynamic from "next/dynamic";

const MailToComp = dynamic(() => import("../../components/Home/MailtoComp"), {
    ssr: false,
});

const HeroHome = props => {
    return (
        <section id="Hero">
            <div className="w-full h-auto relative">
                <div className="hidden md:block w-full bg-black">
                    <Image
                        className="opacity-70"
                        alt="DSi"
                        src={
                            props.featureImage ? props.featureImage : "/images/rectangle3.jpg"
                        }
                        width="2000"
                        height="650"
                        layout="responsive"
                        objectFit="cover"
                        priority
                    />
                </div>
                <div className="block md:hidden w-full bg-black">
                    <Image
                        className="opacity-70"
                        alt="DSi"
                        src={
                            props.featureImage ? props.featureImage : "/images/rectangle3.jpg"
                        }
                        width="320"
                        height="200"
                        layout="responsive"
                        objectFit="cover"
                        priority
                    />
                </div>
                <div className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="grid mid-home-column px-5">
                        <div className="">
                            <p className="md:line-leading-title mb-0 md:mb-4 sm:mb-6 lg:line-leading-title hero-header md:text-left font-bold text-4xl md:text-5xl lg:text-8xl text-white">
                                {props.header}
                            </p>
                            <div className="hidden md:block text-left mt-12">
                                <MailToComp>
                                    <button
                                        className="bg-yellow-btn hover:bg-black hover:text-white text-center text-xl font-headline flex border border-black text-black font-medium px-8 py-2">
                                        Get Started
                                    </button>
                                </MailToComp>
                            </div>
                            <div className="block md:hidden text-left mt-6">
                                <MailToComp>
                                    <button
                                        className="bg-yellow-btn hover:bg-black hover:text-white text-center sm:text-xs md:text-sm lg:text-lg font-headline flex border border-black text-black font-medium px-4 py-2">
                                        Get Started
                                    </button>
                                </MailToComp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default HeroHome;
