import {BsChevronLeft} from "react-icons/bs";

const SamplePrevArrow = props => {
    const {onClick} = props;
    return (
        <div className="prevArrow -mt-28" onClick={onClick}>
            <BsChevronLeft className="text-2xl md:text-3xl"/>
        </div>
    );
};

export default SamplePrevArrow;
