import {BsChevronRight} from "react-icons/bs";

const SampleNextArrow = props => {
    const {onClick} = props;
    return (
        <div className="nextArrow -mt-10 sm:-mt-20 md:-mt-6" onClick={onClick}>
            <BsChevronRight className="h-5 w-5 text-sm md:text-3xl inline-block"/>
        </div>
    );
};

export default SampleNextArrow;
