import Link from "next/link";

const WorkDomain = props => {
    return (
        <div className="grid px-5 mid-home-column">
            <div className="flex flex-col w-full text-center pb-9">
                <h2 className="pb-0 text-3xl font-extrabold leading-tight md:pb-2 font-headline break-wordsmx-auto md:text-5xl">
                    How We Work
                </h2>
                <p className="text-base font-normal text-gray-600 font-headline md:text-2xl">
                    Pick a plan tailored to your needs
                </p>
            </div>
            <div
                className="grid gap-6 sm:grid-rows-1 sm:grid-cols-2 md:grid-rows-1 md:grid-cols-3 lg:grid-rows-1 lg:grid-cols-3">
                <Link href="/works/#Augmentation">
                    <div className="text-black cursor-pointer md:hover:bg-black md:hover:text-white group">
                        <div className="flex flex-col p-6 border border-black text-start h-80">
                            <div className="flex flex-1 pb-4 text-2xl font-extrabold font-headline">
                                Team augmentation
                            </div>
                            <div
                                className="flex flex-1 text-base font-normal font-headline md:overflow-hidden lg:overflow-visible">
                                Looking for software professionals to augment your in-house
                                team? Fill your open positions with carefully selected talents
                                based on the requirements and expertise you need.
                            </div>
                            <div className="flex flex-auto"></div>
                            <div className="flex justify-end flex-initial">
                                <img className="w-5 h-5" alt="DSi" src="/images/arrow.svg"/>
                            </div>
                        </div>

                        <div className="hidden p-6 opacity-0 group-hover:opacity-100 md:block">
                            <div className="flex justify-end w-full bg-black">
                                <img
                                    className="w-5 h-5"
                                    alt="DSi"
                                    src="/images/arrow-white.svg"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
                <Link href="/works/#Development">
                    <div className="md:hover:bg-black text-black md:hover:text-white group cursor-pointer">
                        <div className="flex flex-col text-start p-6 h-80 border border-black">
                            <div className="flex flex-1 font-headline text-2xl font-extrabold pb-4">
                                End-to-end development
                            </div>
                            <div
                                className="flex flex-1 font-headline text-base font-normal md:overflow-hidden lg:overflow-visible">
                                We take your idea, put together a team, and convert it into an
                                impeccable product ready for user testing. Faster, cheaper, and
                                better.
                            </div>
                            <div className="flex flex-auto"></div>
                            <div className="flex flex-initial justify-end">
                                <img className="w-5 h-5" alt="DSi" src="/images/arrow.svg"/>
                            </div>
                        </div>

                        <div className="opacity-0 group-hover:opacity-100 p-6 hidden md:block">
                            <div className="flex bg-black w-full justify-end">
                                <img
                                    className="w-5 h-5"
                                    alt="DSi"
                                    src="/images/arrow-white.svg"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
                <Link href="/works/#Consultancy">
                    <div className="md:hover:bg-black text-black md:hover:text-white group cursor-pointer">
                        <div className="flex flex-col text-start p-6 h-80 border border-black">
                            <div className="flex flex-1 font-headline text-2xl font-extrabold pb-4">
                                Consultancy
                            </div>
                            <div
                                className="flex flex-1 font-headline text-base font-normal md:overflow-hidden lg:overflow-visible">
                                Bring in an experienced consultant from DSi to unlock the full
                                potential of your team and bridge any skill gap.
                            </div>
                            <div className="flex flex-auto"></div>
                            <div className="flex flex-initial justify-end">
                                <img className="w-5 h-5" alt="DSi" src="/images/arrow.svg"/>
                            </div>
                        </div>

                        <div className="opacity-0 group-hover:opacity-100 p-6 hidden md:block">
                            <div className="flex bg-black w-full justify-end">
                                <img
                                    className="w-5 h-5"
                                    alt="DSi"
                                    src="/images/arrow-white.svg"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};
export default WorkDomain;
