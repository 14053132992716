import Slider from "react-slick";
import Image from "next/image";
import SampleNextArrow from "../HomeSlider/ClientSliderNextArrow";
import SamplePrevArrow from "../HomeSlider/ClientSliderPreviousArrow";

const HomeClientsSlider = props => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1280, // tablet breakpoint
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 1024, // tablet breakpoint
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 980, // tablet breakpoint
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 480, // mobile breakpoint
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
        ],
    };

    let dsiClients = props.posts.map((item, index) => {
        return (
            <div key={index} className="w-16 md:w-32 mx-2 justify-center">
                <div className="flex justify-center mb-8">
                    <div className="hidden md:block w-32 bg-white rounded-full">
                        <Image
                            alt={item.title}
                            src={
                                item.feature_image
                                    ? item.feature_image
                                    : "/images/rectangle3.jpg"
                            }
                            width="128"
                            height="128"
                            layout="intrinsic"
                            objectFit="fill"
                            quality={100}
                        />
                    </div>
                    <div className="block md:hidden w-14 bg-white rounded-full">
                        <Image
                            alt={item.title}
                            src={
                                item.feature_image
                                    ? item.feature_image
                                    : "/images/rectangle3.jpg"
                            }
                            width="56"
                            height="56"
                            layout="intrinsic"
                            objectFit="fill"
                            quality={100}
                        />
                    </div>
                </div>
            </div>
        );
    });
    return (
        <div className="md:px-8">
            <Slider {...settings}>{dsiClients}</Slider>
        </div>
    );
};

export default HomeClientsSlider;
