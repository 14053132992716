import {BsChevronRight} from "react-icons/bs";

const SampleNextArrow = props => {
    const {onClick} = props;
    return (
        <div className="nextArrow -mt-28" onClick={onClick}>
            <BsChevronRight className=" text-2xl md:text-3xl inline-block"/>
        </div>
    );
};

export default SampleNextArrow;
