import Slider from "react-slick";
import SampleNextArrow from "../HomeSlider/SampleNextArrow";
import Link from "next/link";
import SamplePrevArrow from "../HomeSlider/SamplePrevArrow";
import { grabImageURLFromGhostPost } from "../../../utils/utility";

import Image from "next/image";

const HomeInnovators = props => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  let dsiInnovators = props.posts.map(item => {
    let img = grabImageURLFromGhostPost(item.html, "onRegularList");
    return (
      <Link key={item.id} href={`./people/innovator/${item.slug}`}>
        <div className="flex flex-col items-center cursor-pointer mb-8 pb-4">
          <div className="hidden lg:flex justify-center hover:shadow-lg">
            <Image
              className="bg-white flex items-center justify-center"
              alt="slider image"
              src={img ? img : "/images/rectangle3.jpg"}
              width="232"
              height="232"
              layout="intrinsic"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="flex lg:hidden justify-center hover:shadow-lg">
            <Image
              className="bg-white flex items-center justify-center"
              alt="slider image"
              src={img ? img : "/images/rectangle3.jpg"}
              width="180"
              height="180"
              layout="intrinsic"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="flex flex-col items-center md:block text-center mt-2">
            <p className="text-black font-headline text-xl font-extrabold mb-1">
              {item.meta_title}
            </p>
            <p className="text-gray-600 text-sm items-center text-center font-headline font-semibold">
              {item.meta_description}
            </p>
          </div>
        </div>
      </Link>
    );
  });
  return (
    <div className="">
      <Slider {...settings}>{dsiInnovators}</Slider>
    </div>
  );
};

export default HomeInnovators;
