import Link from "next/link";
import Image from "next/image";

const HomeBlogs = props => {
  let homeItems = props.blog.map((item, index) => {
    return (
      <Link href={props.path + item.slug} key={index}>
        <div className="ml-4 mb-10">
          <div className="flex flex-col sm:flex-row">
            <div className="flex gap-6 flex-row w-full hover:cursor-pointer group">
              <a href={props.path + item.slug} className="w-24 h-24">
                <Image
                  alt={item.title}
                  src={
                    item.feature_image
                      ? item.feature_image
                      : "/images/rectangle3.jpg"
                  }
                  width="90"
                  height="90"
                  layout="fixed"
                  objectFit="cover"
                />
              </a>

              <div className="group break-words flex flex-col">
                <p className="text-black font-headline text-xl font-extrabold m-0 group-hover:text-blue-600">
                  {item.title}
                </p>
                <p className="w-full text-xs mt-1 font-headline font-normal text-gray-700">
                  {item.meta_title} <span className="">|</span>
                  {item.meta_description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  });
  return <div className="grid grid-cols-1 sm:grid-cols-2">{homeItems}</div>;
};

export default HomeBlogs;
