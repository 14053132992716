import { Fragment } from "react";
import Link from "next/link";
import Head from "next/head";
import HomeNews from "../components/Home/HomeNews";
import HomeBlogs from "../components/Home/HomeBlogs";
import HomeService from "../components/Home/HomeService";
import HomeContributors from "../components/Home/HomeContributors";
import HeroHome from "../components/HeroSection/HeroHome";
import { getHomePageDataBySlug, getPosts } from "../utils/GhostAPI";
import HomeClientsSlider from "../components/Home/HomeSlider/HomeClientsSlider";
import HomeInnovatorsSlider from "../components/Home/HomeSlider/HomeInnovators";
import WorkDomain from "../components/Home/WorkDomain";
import HomeResult from "../components/Home/Result";
import HomeProjectList from "../components/Lists/HomeProjectList";
import Certification from "../components/Home/Certification";
import Award from "../components/Home/Award";
import HomeServiceSection from "../components/Home/ServiceSection";
import { ENDPOINTS, TAGS } from "../utils/api-endpoints";

export async function getStaticProps(context) {
  let pageData = await getHomePageDataBySlug(ENDPOINTS.HOME);
  // latest news fetch
  const fetchedNews = {
    filter: "tag:" + TAGS.NEWS + "+tag:" + TAGS.FEATUREDONHOME,
    fields: "id,slug,title,published_at,custom_excerpt,feature_image",
    limit: "all",
  };
  // latest blogs fetch
  const fetchedBlogs = {
    filter: "tag:" + TAGS.BLOG + "+tag:" + TAGS.FEATUREDONHOME,
    fields: "id,slug,title,meta_title,meta_description,feature_image",
    limit: "all",
  };
  // latest service fetch
  const fetchedServices = {
    filter: "tag:" + TAGS.SERVICE + "+tag:" + TAGS.FEATUREDONHOME,
    fields: "title,custom_excerpt,updated_at,feature_image",
    limit: "all",
    order: "updated_at DESC",
  };

  // latest projects fetch
  const fetchedFeaturedWorks = {
    filter: "tag:" + TAGS.PROJECT + "+tag:" + TAGS.FEATUREDONHOME,
    fields: "id,slug,title,custom_excerpt,meta_title,feature_image",
    limit: "all",
  };

  // latest Client fetch
  const fetchedClients = {
    filter: "tag:" + TAGS.CLIENT,
    fields: "title,feature_image",
    limit: "all",
    order: "updated_at DESC",
  };
  // our innovators fetch
  const fetchedDsiInnovators = {
    filter: "tag:" + TAGS.PEOPLE + "+tag:featuredonhome",
    limit: 12,
  };
  // real results fetch
  const fetchedDsiRealResults = {
    filter: "tag:" + TAGS.RESULT + "+tag:featuredonhome",
    fields: "title,custom_excerpt,meta_title,meta_description",
    limit: "all",
  };

  let homeNews = await getPosts(fetchedNews);
  let homeBlogs = await getPosts(fetchedBlogs);
  let homeServices = await getPosts(fetchedServices);
  let homeFeaturedWorks = await getPosts(fetchedFeaturedWorks);
  let homeInnovators = await getPosts(fetchedDsiInnovators);
  let newclients = await getPosts(fetchedClients);
  let homeRealResults = await getPosts(fetchedDsiRealResults);

  return {
    props: {
      pageData: pageData,
      postsData: {
        news: homeNews,
        blogs: homeBlogs,
        services: homeServices,
        works: homeFeaturedWorks,
        dsiclients: newclients,
        innovators: homeInnovators,
        realResults: homeRealResults,
      },
    },
    revalidate: 3600,
  };
}

const Home = (props) => {
  return (
    <Fragment>
      <Head>
        <title>Home | Custom Software Company in Bangladesh</title>
        <meta
          name="description"
          content="DSi is an international software company based in Dhaka, Bangladesh. We have been successfully providing software services since 2001 in both the local and the global markets including the USA, England, New Zealand, Asia, and Africa."
        />
      </Head>
      <HeroHome
        featureImage={props.pageData.feature_image}
        header={props.pageData.meta_title}
        tagline={props.pageData.meta_description}
      />

      <div className="bg-black">
        <div className="grid px-5 mid-home-column">
          <div className="flex flex-col py-12 bg-black md:pb-12 md:pt-12 lg:pb-12 md:flex-row">
            <div className="flex flex-col pb-10 border-b-4 md:items-start md:w-4/5 md:border-b-0 md:border-r-4 border-sky-btn md:pb-0">
              <p className="flex flex-col text-2xl font-extrabold text-white md:w-4/5 font-headline md:text-4xl lg:text-4xl">
                Build a better business with a superior custom software
              </p>
              <p className="w-full pt-4 text-base font-normal text-gray-300 font-headline md:w-4/5 md:text-2xl lg:text-2xl">
                A pool of 270+ skilled developers are ready to help you
                materialize your technology product ideas
              </p>
            </div>
            <div className="flex flex-row items-end justify-center mt-8 ml-0 md:mt-0 md:flex-col md:ml-14 lg:ml-10 md:items-start">
              <div className="flex mx-0 md:w-52">
                <p className="font-extrabold text-white md:line-leading-title text-7xl md:text-8xl font-headline ">
                  22
                </p>
                <span className="-mt-2 text-4xl font-semibold text-white md:text-6xl">
                  +
                </span>
              </div>
              <p className="flex text-3xl font-normal text-right text-white md:text-left font-headline">
                Years of experience
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* work domain */}
      <div className="w-full py-12 bg-gray-100 md:py-28">
        <WorkDomain />
      </div>
      {/* service basket */}
      <div className="w-full py-12 bg-white md:py-28">
        <div className="grid px-5 mid-home-column">
          <div className="pb-9 md:pb-20">
            <h2 className="flex flex-col items-center mb-1 text-3xl font-extrabold text-center break-words font-headline md:text-5xl">
              Service Basket
            </h2>
            <p className="flex flex-col items-center text-base leading-8 text-gray-600 font-headline md:text-2xl">
              For bespoke software solutions
            </p>
          </div>
          <HomeServiceSection />
        </div>
      </div>
      {/* proven process */}
      <div className="w-full py-12 bg-gray-100 sm:py-28">
        <div className="grid px-5 mid-home-column">
          <div className="items-center pb-9 md:pb-24">
            <h2 className="flex flex-col items-center mb-1 text-3xl font-extrabold text-center break-words font-headline md:text-5xl">
              Proven Process, Real Results
            </h2>
            <p className="flex flex-col items-center text-base leading-8 text-gray-600 font-headline md:text-2xl">
              Numbers that make a difference
            </p>
          </div>
          <div className="grid justify-center grid-cols-1 gap-16 md:grid-rows-3 md:grid-cols-2 lg:grid-rows-2 lg:grid-cols-3 md:gap-20">
            <HomeResult service={props.postsData.realResults} />
          </div>
        </div>
      </div>

      {/* Whats Happening */}
      <div className="w-full py-12 bg-white md:py-28">
        <div className="mid-home-column">
          <div className="items-center pb-9 md:pb-20">
            <h2 className="flex flex-col items-center mb-1 text-3xl font-extrabold text-center break-words font-headline md:text-5xl">
              Happening Now
            </h2>
            <p className="flex flex-col items-center text-base leading-8 text-gray-600 font-headline md:text-2xl">
              DSi's latest news
            </p>
          </div>
          <div className="flex mx-3 sm:mx-0 lg:mx-8">
            <div className="w-full">
              <HomeNews news={props.postsData.news} path="/news/" />
              <div className="grid justify-items-center">
                <Link href="/news">
                  <button className="flex px-8 py-2 mx-auto text-sm font-medium border-2 border-black hover:bg-black hover:text-white font-button md:mx-4">
                    SEE ALL NEWS
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* blogs */}
      <div className="w-full pb-12 bg-white md:pb-28">
        <div className="mid-home-column">
          <div className="items-center pb-9 md:pb-20">
            <h2 className="flex flex-col items-center mb-1 text-3xl font-extrabold text-center break-words font-headline md:text-5xl">
              Words and Thoughts
            </h2>
            <p className="flex flex-col items-center text-base leading-8 text-gray-600 font-headline md:text-2xl">
              DSi’s Latest Blog posts
            </p>
          </div>
          <div className="flex mx-3 sm:mx-0 lg:mx-8">
            <div className="w-full">
              <HomeBlogs blog={props.postsData.blogs} path="/blogs/" />
              <div className="grid justify-items-center">
                <Link href="blogs">
                  <button className="flex px-8 py-2 mx-auto text-sm font-medium border-2 border-black hover:bg-black hover:text-white font-button md:mx-4">
                    SEE ALL BLOGS
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Happy Clients */}
      <div className="w-full pt-12 pb-20 bg-white md:pb-32">
        <div className="px-5 mid-column">
          <div className="items-center pb-12 md:pb-16">
            <h2 className="flex flex-col items-center mb-1 text-3xl font-extrabold text-center break-words font-headline md:text-5xl">
              Our Happy Clients
            </h2>
            <p className="flex flex-col items-center text-base leading-8 text-gray-600 font-headline md:text-2xl">
              Home & abroad
            </p>
          </div>
          <div className="slideWrapperContainer" id="clientSlider">
            <HomeClientsSlider posts={props.postsData.dsiclients} />
          </div>
        </div>
      </div>

      {/* Where We Contributed */}
      <div className="w-full py-12 bg-black md:py-28">
        <div className="px-5 mid-home-column">
          <div className="items-center pb-9 md:pb-20">
            <h2 className="flex flex-col mb-1 text-3xl font-extrabold text-center text-white break-words font-headline md:text-5xl">
              Verticals We Contributed To
            </h2>
            <p className="flex flex-col text-base leading-8 text-center text-gray-200 font-headline md:text-2xl">
              From mass audience markets to niche segments
            </p>
          </div>
          <div className="grid grid-cols-1 grid-rows-6 md:grid-cols-2 md:grid-rows-3 gap-x-20 gap-y-14">
            <HomeContributors path="/projects/" />
          </div>
        </div>
      </div>
      {/* Featured Works */}
      <div className="flex w-full py-12 bg-white sm:py-28">
        <div className="px-5 mid-home-column">
          <div className="flex flex-col items-center pb-9 md:pb-16">
            <h2 className="mb-1 text-3xl font-extrabold text-center text-black font-headline md:text-5xl">
              Featured Works
            </h2>
            <p className="flex text-base leading-8 text-center text-gray-600 font-headline md:text-2xl">
              Team augmentations, partnerships, and ground up projects
            </p>
          </div>
          <div className="grid">
            <HomeProjectList
              data={props.postsData.works}
              title="GO TO PROJECT"
              path="/projects/"
            />
            <div className="flex flex-col items-center">
              <Link href={"projects"}>
                <button className="flex px-8 py-2 mx-auto mt-8 text-sm font-medium text-center border-2 border-black hover:bg-black hover:text-white font-button md:mx-4">
                  SEE ALL PROJECTS
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Meet the mentors */}
      <div className="hidden w-full bg-gray-100 py-9 md:py-28 md:block">
        <div className="mx-auto mid-column">
          <div className="flex flex-col items-center justify-center md:-mx-4">
            <div className="w-full mx-auto">
              <h2 className="flex flex-col items-center mb-1 text-3xl font-extrabold text-center text-black break-words font-headline md:text-5xl">
                Meet the Innovators
              </h2>
              <p className="flex flex-col items-center text-base leading-8 text-gray-600 font-headline md:text-2xl">
                They are up for a new project
              </p>
              <div className="pb-4 mt-16 2xl:px-14">
                <div className="slideWrapperContainer">
                  <HomeInnovatorsSlider posts={props.postsData.innovators} />
                </div>
              </div>
            </div>
            <div className="flex items-center mt-12">
              <Link href={"people/1/"}>
                <button className="px-8 py-2 mx-auto mt-8 text-sm font-medium text-center border-2 border-black hover:bg-black hover:text-white font-button md:mx-4">
                  SEE ALL INNOVATORS
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mx-auto bg-white py-9 md:py-28">
        <Award />
      </div>
      <div className="w-full bg-gray-100 py-9 md:py-28">
        <Certification />
      </div>
    </Fragment>
  );
};

export default Home;
