import Image from "next/image";
import Link from "next/link";

const HomeContributors = props => {
  return (
    <>
      <Link href={props.path + "olwel"}>
        <div className="flex items-center border-b-2 border-gray-800 pb-6 md:hover:border-white group cursor-pointer">
          <div className="w-28 mr-8">
            <Image
              alt="healthcare system"
              src="/images/contribute/Healthcare.png"
              width="60"
              height="60"
              layout="fixed"
            />
          </div>
          <div className="break-words flex flex-col">
            <p className="font-body font-extrabold text-xl md:text-2xl text-white">
              Healthcare
            </p>
            <p className="hidden md:block font-body text-base font-normal mt-1 text-gray-200 group-hover:text-white">
              Innovative solutions for providing doorstep healthcare services to
              homebound patients
            </p>
          </div>
        </div>
      </Link>
      <Link href={props.path + "verification"}>
        <div className="flex items-center border-b-2 border-gray-800 pb-6 md:hover:border-white group cursor-pointer">
          <div className="w-28 mr-8">
            <Image
              alt="digital verification"
              src="/images/contribute/Digi_verific.png"
              width="60"
              height="60"
              layout="fixed"
            />
          </div>
          <div className="break-words flex flex-col">
            <p className="font-body font-extrabold text-xl md:text-2xl text-white">
              Digital Verification
            </p>
            <p className="hidden md:block font-body text-base font-normal mt-1 text-gray-200 group-hover:text-white">
              Turnkey design verification testing services using SystemVerilog
              and UVM
            </p>
          </div>
        </div>
      </Link>
      <Link href={props.path + "banking-compliance-system"}>
        <div className="flex items-center border-b-2 border-gray-800 pb-6 md:hover:border-white group cursor-pointer">
          <div className="w-28 mr-8">
            <Image
              alt="banking system"
              src="/images/contribute/Banking.png"
              width="60"
              height="60"
              layout="fixed"
            />
          </div>
          <div className="break-words flex flex-col">
            <p className="font-body font-extrabold text-xl md:text-2xl text-white">
              Banking
            </p>
            <p className="hidden md:block font-body text-base font-normal mt-1 text-gray-200 group-hover:text-white">
              Our banking solutions are being used by over 200 financial
              institutions across the US
            </p>
          </div>
        </div>
      </Link>
      <Link href={props.path + "odms"}>
        <div className="flex items-center border-b-2 border-gray-800 pb-6 md:hover:border-white group cursor-pointer">
          <div className="w-28 mr-8">
            <Image
              alt="off dock management sysytem"
              src="/images/contribute/Off_Dock.png"
              width="60"
              height="60"
              layout="fixed"
            />
          </div>
          <div className="break-words flex flex-col">
            <p className="font-body font-extrabold text-xl md:text-2xl text-white">
              Off-Dock
            </p>
            <p className="hidden md:block font-body text-base font-normal mt-1 text-gray-200 group-hover:text-white">
              40% of all container and cargo related import-export operations of
              Bangladesh are being performed using our ODMS solution
            </p>
          </div>
        </div>
      </Link>
      <Link href={props.path + "recenseo"}>
        <div className="flex items-center border-b-2 border-gray-800 pb-6 md:hover:border-white group cursor-pointer">
          <div className="w-28 mr-8">
            <Image
              alt="document discovery"
              src="/images/contribute/Doc_discovery.png"
              width="60"
              height="60"
              layout="fixed"
            />
          </div>

          <div className="break-words flex flex-col">
            <p className="font-body font-extrabold text-xl md:text-2xl text-white">
              Document Discovery
            </p>
            <p className="hidden md:block font-body text-base font-normal mt-1 text-gray-200 group-hover:text-white">
              A robust document management tool that uses machine-learning data
              analytics framework
            </p>
          </div>
        </div>
      </Link>
      <Link href={props.path + "jenzabar-3"}>
        <div className="flex items-center border-b-2 border-gray-800 pb-6 md:hover:border-white group cursor-pointer">
          <div className="w-28 mr-8">
            <Image
              alt="higher education"
              src="/images/contribute/Higher_ed.png"
              width="60"
              height="60"
              layout="fixed"
            />
          </div>
          <div className="break-words flex flex-col">
            <p className="font-body font-extrabold text-xl md:text-2xl text-white">
              Higher Education
            </p>
            <p className="hidden md:block font-body text-base font-normal mt-1 text-gray-200 group-hover:text-white">
              Developed comprehensive education management solutions for
              nationwide implementation
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default HomeContributors;
