import moment from "moment";
import Link from "next/link";
import Image from "next/image";

const HomeNews = props => {
  let homeItems = props.news.map((item, index) => {
    return (
      <Link href={props.path + item.slug} key={index}>
        <div className="mx-4 mb-10 hover:cursor-pointer">
          <div className="flex flex-col sm:flex-row">
            <div className="flex gap-2 sm:gap-6 flex-col sm:flex-row w-full group">
              <a
                key={index}
                href={props.path + index}
                className="hidden sm:block w-80 h-48"
              >
                <Image
                  alt={item.title}
                  src={
                    item.feature_image
                      ? item.feature_image
                      : "/images/rectangle3.jpg"
                  }
                  width="299"
                  height="188"
                  layout="fixed"
                  objectFit="cover"
                  quality={100}
                />
              </a>
              <a
                key={index}
                href={props.path + index}
                className="block sm:hidden"
              >
                <Image
                  alt={item.title}
                  src={
                    item.feature_image
                      ? item.feature_image
                      : "/images/rectangle3.jpg"
                  }
                  width="299"
                  height="188"
                  layout="responsive"
                  quality={100}
                />
              </a>
              <div className="group md:w-2/3 break-words flex flex-col">
                <p className="text-black font-headline text-xl font-extrabold m-0 hover:cursor-pointer group-hover:text-blue-600">
                  {item.title}
                </p>
                <p className="w-full text-xs mt-1 font-headline font-normal text-gray-700 hover:cursor-pointer">
                  {moment(item.published_at).format("MMMM Do YYYY")}
                </p>
                <p className="hidden md:block font-headline mt-4 text-base text-gray-600 font-normal">
                  {item.custom_excerpt}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  });
  return <div>{homeItems}</div>;
};

export default HomeNews;
