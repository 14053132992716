import Image from "next/image";

const Certification = () => {
  return (
    <div className="px-5 mid-home-column">
      <div className="flex flex-col items-center">
        <h2 className="text-3xl font-extrabold text-center text-black font-headline">
        Certification & Appraisal
        </h2>
        <div className="lg:self-start lg:ml-6 xl:ml-20 flex flex-col items-center  mt-12 text-center lg:flex-row">
          <div className="hidden text-center lg:block">
            <Image
              alt="certificate"
              src="/images/home/iso-9001.png"
              width="200"
              height="200"
              layout="fixed"
              quality={100}
            />
          </div>
          <div className="block text-center lg:hidden">
            <Image
              alt="certificate"
              src="/images/home/iso-9001.png"
              width="114"
              height="103"
              layout="fixed"
              quality={100}
            />
          </div>
          <div className="flex flex-col mx-auto ml-0 md:ml-10 mt-4">
            <span className="flex pb-2">
              <span className="mr-4 text-3xl font-extrabold md:text-6xl font-body">
                ISO
              </span>
              <span className="text-3xl font-normal md:text-6xl font-body">
                9001:2015
              </span>
            </span>
            <span className="text-base font-normal text-gray-600 lg:text-left md:text-2xl font-body">
              Certified since 2022
            </span>
          </div>
        </div>
        <div className="flex flex-col  mt-12 text-center items-center lg:flex-row">
          <div className="hidden text-center lg:block">
            <Image
              alt="certificate"
              src="/images/home/iso-certificate-2022.svg"
              width="200"
              height="200"
              layout="fixed"
              quality={100}
            />
          </div>
          <div className="block text-center lg:hidden">
            <Image
              alt="certificate"
              src="/images/home/iso-certificate-2022.svg"
              width="114"
              height="103"
              layout="fixed"
              quality={100}
            />
          </div>
          <div className="flex flex-col mx-auto ml-0 md:ml-10 mt-4">
            <span className="flex pb-2">
              <span className="mr-4 text-3xl font-extrabold md:text-6xl font-body">
                ISO/IEC
              </span>
              <span className="text-3xl font-normal md:text-6xl font-body">
                27001:2022
              </span>
            </span>
            <span className="text-base font-normal text-gray-600 lg:text-left md:text-2xl font-body">
              Certified since 2020
            </span>
          </div>
        </div>
        {/* cmmi */}
        <div className=" lg:mx-8 xl:mx-24 flex flex-col mt-12 text-center lg:flex-row">
          <a href="https://cmmiinstitute.com/pars/appraisals/68701" target="_blank">
            <Image
              alt="CMMI appraisals"
              src="/images/home/cmmi.svg"
              width="384"
              height="120"
              quality={100}
            />
            </a>
      
        </div>
      </div>
    </div>
  );
};

export default Certification;
