import Link from "next/link";
import Image from "next/image";

const HomeServiceSection = props => {
  return (
    <div className="grid justify-center grid-cols-1 gap-6 sm:grid-rows-4 sm:grid-cols-2 md:grid-rows-3 md:grid-cols-3 lg:grid-rows-3 lg:grid-cols-3">
      <Link
        key="scalable"
        href={
          "/services#" +
          "Highly Scalable System Design".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/scalable.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              Highly Scalable System Design
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Best-in-class solutions, leveraging the latest tech and trends
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="cloud"
        href={
          "/services#" + "Cloud Services".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/cloud.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              Cloud Services
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Provision services as needed, reduce overhead
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="deveops"
        href={
          "/services#" + "DevOps Services".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/deveops.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              DevOps Services
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Deliver at speed, accomplish more!
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="data"
        href={
          "/services#" +
          "Big Data & Data Science".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/data.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              Big Data & Data Science
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Drive business transformation through data analytics
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="database"
        href={
          "/services#" +
          "Database Management".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/database.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              Database Management
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Your trusted DBA experts
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="Embeded_system"
        href={
          "/services#" +
          "Embedded System Design".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/Embeded_system.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              Embedded System Design
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Digital Verification and Protocol Design for Semiconductor Devices
            </p>
          </div>
        </div>
      </Link>
      <Link key="vlsi" href="/vlsi">
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/digital.svg"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              VLSI
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Drive business transformation through data analytics
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="sqa"
        href={"/services#" + "SQA".toLowerCase().split(" ").join("-")}
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/sqa.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              SQA
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Ensure quality of design and conformance
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="mbd"
        href={
          "/services#" +
          "Mobile Application Development".toLowerCase().split(" ").join("-")
        }
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/mobile.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              Mobile Application Development
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              From concept to full-fledged app deployment
            </p>
          </div>
        </div>
      </Link>
      <Link key="ros" href="/ros">
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/ROSicon.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              ROS
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Robot operating system and applications
            </p>
          </div>
        </div>
      </Link>
      <Link
        key="ux"
        href={"/services#" + "UX Design".toLowerCase().split(" ").join("-")}
      >
        <div className="hover:bg-white hover:shadow-lg cursor-pointer items-center md:items-start p-8 flex flex-col border border-indigo-100 overflow-hidden group">
          <div className="w-12">
            <Image
              alt=""
              src="/images/service/ux.png"
              width="48"
              height="48"
              layout="responsive"
              objectFit="fill"
              quality={100}
            />
          </div>
          <div className="mt-4">
            <p className="font-extrabold font-headline text-xl text-center md:text-left pb-2 leading-relaxed block mb-0 group-hover:text-blue-600">
              UX Design
            </p>
            <p className="font-headline font-medium text-base leading-6 text-gray-700 hidden md:block">
              Simple, intuitive, and aesthetically pleasing!
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default HomeServiceSection;
